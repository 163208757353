import React from 'react';
import classNames from 'classnames';
// import PropTypes from 'prop-types';
import BackgroundImage from 'Components/BackgroundImage';
import s from './CardOfficeLarge.module.scss';

export const CardOfficeLarge = ({
    image,
    title,
    info,
    email,
    phone,
    map,
    streetAddress,
    zipCity,
    postalAddress,
}) => {

    const hasImage = image && image.src;
    const imageClasses = classNames(
        s['Root__Image'],
        {[s['Root__Image--Placeholder']]: !hasImage },
    );

    return (
        <div className={s['Root']}>
            <div className={s['Root__ImageContainer']}>
                <BackgroundImage
                    className={imageClasses}
                    image={image}
                />
            </div>

            <div className={s['Root__Container']}>

                {title && (
                    <h1 className={s['Root__Title']}>
                        {title}
                    </h1>
                )}

                {info && (
                    <p className={s['Root__Preamble']}>
                        {info}
                    </p>
                )}

                <div className={s['Root__Info']}>
                    {map && map.href && (
                        <p className={classNames(s['Root__LinkWrapper'], s['Root__LinkWrapper--Office'])}
                        >
                            <a
                                rel="noopener norefeerer"
                                target="_blank"
                                className={s['Root__Link']}
                                href={map.href}
                            >
                                {`${streetAddress}, ${zipCity}`}
                            </a>
                        </p>
                    )}
                    {postalAddress && (
                        <p className={classNames(s['Root__LinkWrapper'], s['Root__LinkWrapper--Postal'])}>{postalAddress}</p>
                    )}
                    {email && (
                        <p className={classNames(s['Root__LinkWrapper'], s['Root__LinkWrapper--Email'])}>
                            <a
                                className={s['Root__Link']}
                                href={'mailto:' + email}>
                                {email}
                            </a>
                        </p>
                    )}
                    {phone && phone.length > 5 && (
                        <p className={classNames(s['Root__LinkWrapper'], s['Root__LinkWrapper--Phone'])}>
                            <a
                                className={s['Root__Link']}
                                href={'tel:' + phone}>
                                {phone}
                            </a>
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

CardOfficeLarge.propTypes = {};

export default CardOfficeLarge;
